import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Input } from 'antd';

const { Option } = Select;
const REQUEST_TYPES = ['Consulta', 'Incidente'];
const BUS_COMPANIES = ['Millantour', 'Hualpen', 'Shuftan'];
const WORK_PLACES = [
  'Escuela Servicio',
  'Ciudad Empresarial',
  'Plantas Productivas',
  'CD Quilicura',
  'CD El Peñón',
  'Campos de Chile',
  'Pto. Santiago'
];
const SHIFTS = [
  'Ingreso Mañana',
  'Salida Mañana',
  'Ingreso Tarde',
  'Salida Tarde',
  'Ingreso Noche',
  'Salida Noche'
];
const INCIDENT_TYPES = [
  'Bus no realiza recorrido',
  'No cumple con horario definido',
  'Realizó desvío en la ruta',
  'Excede velocidad máxima permitida',
  'No se detiene en parada establecida',
  'Conductor no cumple con pilares compañía',
  'Bus no cumple con estándar (limpieza, calefacción, etc.)',
  'No aplica'
];

// props: requestType, busCompany, workPlace, shift, incidentType, route

const BusesForm = (props) => {
  const [incidentTypeValue, setIncidentTypeValue] = useState(
    'Seleccionar tipo de incidente'
  );

  const [incidentTypeDisabled, setIncidentTypeDisabled] = useState(false);

  const { setBusesData, busesData } = props;

  const handleRequestType = (requestType) => {
    if (requestType === 'Consulta') {
      setIncidentTypeValue('No aplica');
      setBusesData({ ...busesData, requestType, incidentType: 'No aplica' });
      setIncidentTypeDisabled(true);
    } else {
      setBusesData({ ...busesData, requestType });
      setIncidentTypeDisabled(false);
    }
  };

  const handleBusCompany = (busCompany) => {
    setBusesData({ ...busesData, busCompany });
  };

  const handleWorkPlace = (workPlace) => {
    setBusesData({ ...busesData, workPlace });
  };

  const handleShift = (shift) => {
    setBusesData({ ...busesData, shift });
  };
  const handleIncidentType = (incidentType) => {
    setIncidentTypeValue(incidentType);
    setBusesData({ ...busesData, incidentType });
  };

  const onChangeRoute = (event) => {
    setBusesData({ ...busesData, route: event.target.value });
  };

  return (
    <div>
      <Form.Item label='Tipo de requerimiento'>
        <Select
          id='requestType'
          placeholder='Seleccionar una opción'
          onChange={(requestType) => {
            handleRequestType(requestType);
          }}
        >
          {REQUEST_TYPES.map((requestType) => (
            <Option key={requestType} value={requestType}>
              {requestType}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label='Empresa de Buses'>
        <Select
          id='busCompany'
          placeholder='Seleccionar empresa'
          onChange={(busCompany) => {
            handleBusCompany(busCompany);
          }}
        >
          {BUS_COMPANIES.map((busCompany) => (
            <Option key={busCompany} value={busCompany}>
              {busCompany}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label='Lugar de Trabajo'>
        <Select
          id='workPlace'
          placeholder='Seleccionar lugar de trabajo'
          onChange={(workPlace) => {
            handleWorkPlace(workPlace);
          }}
        >
          {WORK_PLACES.map((workPlace) => (
            <Option key={workPlace} value={workPlace}>
              {workPlace}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label='Turno Afectado'>
        <Select
          id='shift'
          placeholder='Seleccionar turno afectado'
          onChange={(shift) => {
            handleShift(shift);
          }}
        >
          {SHIFTS.map((shift) => (
            <Option key={shift} value={shift}>
              {shift}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label='Tipo de Incidente'>
        <Select
          id='incidentType'
          value={incidentTypeValue}
          placeholder='Seleccionar tipo de incidente'
          disabled={incidentTypeDisabled}
          onChange={(incidentType) => {
            handleIncidentType(incidentType);
          }}
        >
          {INCIDENT_TYPES.map((incidentType) => (
            <Option key={incidentType} value={incidentType}>
              {incidentType}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label='Ruta'>
        <Input
          id='route'
          onChange={(event) => onChangeRoute(event)}
          placeholder='Ingresar nombre de la ruta'
        />
      </Form.Item>
    </div>
  );
};

BusesForm.propTypes = {
  setBusesData: PropTypes.func,
  busesData: PropTypes.object
};

BusesForm.defaultProps = {
  setBusesData: () => {},
  busesData: {}
};

export default BusesForm;
