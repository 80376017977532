import { fullCapitalizeFormat } from '../strings';

export const VIEW_AS_EVALUATOR = 'EVALUATOR';
export const VIEW_AS_VALIDATOR = 'VALIDATOR';
export const VIEW_AS_EVALUATED = 'EVALUATED';

export const PENDING = 'PENDING';
export const EVALUATED = 'EVALUATED';
export const VALIDATED = 'VALIDATED';
export const REJECTED = 'REJECTED';
export const JUSTIFIED = 'JUSTIFIED';
export const COMMUNICATED = 'COMMUNICATED';
export const NOT_COMPLETED = 'NOT_COMPLETED';
export const DRAFT = 'DRAFT';
export const yearCurrentEvaluation = 2024;
export const yearCurrentEvaluationPotential = 2024;

export const getIconStatus = (status) => {
  status = status.toUpperCase();
  switch (status) {
  case COMMUNICATED:
    return 'icon-me-check success-icon-color';
  case PENDING:
  case EVALUATED:
  case REJECTED:
  case VALIDATED:
    return 'icon-me-feedback-pending pending-icon-color';
  case JUSTIFIED:
    return 'icon-ml-justificacion';
  default:
    return 'icon-me-error rejected-icon-color';
  }
};
export const getLabelStatus = (status) => {
  switch (status.toUpperCase()) {
  case PENDING: case EVALUATED:
    return 'En proceso';
  case REJECTED:
    return 'Rechazada';
  case JUSTIFIED:
    return 'Justificada';
  case VALIDATED:
    return 'En proceso';
  case COMMUNICATED:
    return 'Finalizada';
  case NOT_COMPLETED:
    return 'No finalizada';
  default:
    return '';
  }
};

export const getEvaluatorAndValidator = (evaluator, validator) => [
  { label: 'Evaluador', value: fullCapitalizeFormat(`${evaluator?.name || ''} ${evaluator?.fatherLastName || ''}`) },
  { label: 'Validador', value: fullCapitalizeFormat(`${validator?.name || ''} ${validator?.fatherLastName || ''} `) }
];

export const gqlMessages = `{
  title
  status
  to
  message{
    isBold
    content
    link
  }
  footer{
    isBold
    content
  }
}`;

export const MESSAGE_DISABLED_PERFORMACE_EVALUATION = 'El proceso de Evaluación de Desempeño ya finalizó, si esta quedo pendiente  comunícate con tu People Business Partner o Líder de Persona de Mercado Desplegado';
