import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import EvaluationSummary from '../../../mievolucion/shared/PerformanceEvaluation/EvaluationSummary';
import InfoMessage from '../../../shared/InfoMessage';
import { getFullName } from '../../../../helpers/user';

const LastEDDEvaluation = (props) => {
  const {
    lastEDDEvaluation, currentEDD, handleRestoreEDD, restoreLastEvaluationLoading
  } = props;

  if (!lastEDDEvaluation || !currentEDD) {
    return null;
  }

  const evaluatedFullname = getFullName(currentEDD?.evaluated);
  return (
    <div className='mt-3 text-center'>
      <h4>Restaurar evaluación anterior de {evaluatedFullname} </h4>

      <div className='border p-3 rounded'>
        <h4>Evaluación actual</h4>
        <InfoMessage
          message={`A continuación podrás ver el detalle de la Evaluación actual de ${evaluatedFullname}`}
          classes='mb-3'
        />
        <EvaluationSummary
          openEvaluation={false}
          evaluation={currentEDD} />
      </div>
      <div className='border mt-3 p-3 rounded'>
        <h4>Última evaluación registrada</h4>
        <InfoMessage
          message={`A continuación podrás ver el detalle de la última Evaluación de ${evaluatedFullname} registrado en el historial, si quieres dejar su evaluación en estado pendiente con la siguiente información, presiona el boton  "Restaurar Evaluación con está información"`}
          classes='mb-3'
        />
        <EvaluationSummary
          openEvaluation={false}
          evaluation={lastEDDEvaluation} />

      </div>
      <Button
        loading={restoreLastEvaluationLoading}
        className='mt-3' type='primary' onClick={handleRestoreEDD}>
        {`${restoreLastEvaluationLoading ? 'Restaurando' : 'Restaurar'} Evaluación con está información`}
      </Button>

    </div>
  );
};

LastEDDEvaluation.propTypes = {
  lastEDDEvaluation: PropTypes.object,
  currentEDD: PropTypes.object,
  handleRestoreEDD: PropTypes.func,
  restoreLastEvaluationLoading: PropTypes.bool
};

LastEDDEvaluation.defaultProps = {
  lastEDDEvaluation: null,
  currentEDD: null,
  handleRestoreEDD: () => {},
  restoreLastEvaluationLoading: false
};

export default LastEDDEvaluation;
