/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import { gqlMessages } from '../../../helpers/myEvolution/evaluations';

const user = `
  {
    _id
    rut
    validator
    evaluator
    evaluationType
    additionalField5
    additionalField4
    position
    emailOffice
    fatherLastName
    name
    npres
    atLeastOnceClosedStatus
  }
`;
const categoryFields = `
    {
      _id
      name
      type
      description
      skillDescription
      finalSkillDescription
      objetivesDescription
      finalObjetivesDescription
      score
    }
`;

const objectives = `
objectives {
  weight
  _id
  order
  categorySelectedAuto
  objective{
    _id
    name
    description
    year
    createdManual
    createdAt
    updatedAt
  }
  macro{
    _id
    name
  }
  strategicPriority {
    name
  }
  successMeasures {
    description
    dateMeasure
    updates {
      q1 {
        status
      }
      q2 {
        status
      }
      q3 {
        status
      }
      q4 {
        status
      }
    }
  }
  pillar{
    _id
    name
  }
  successMeasures{
    description
    dateMeasure
    updatedAt
    updates{
      q1{
        status
      }
      q2{
        status
      }
      q3{
        status
      }
      q4{
        status
      }     
    }
  }
  categorySelected  ${categoryFields}
}
`;
const skills = `
  skills{
    _id
    name
    categorySelected ${categoryFields}
    categorySelectedAuto
    behaviors {
      title
      description
    }
    SAPCounter
  }
`;

export const edd = `
{

  _id
  opinion{
    answers {
      value
    } 
  }
  evaluatorCanEditObjectives
  hasCalibration
  active
  year
  rut
  status
  strengths
  opportunities
  reason
  UCMId
  isAutoevaluation
  hasOpinion
  lastStepEvaluation{
    component
    step
  }
  messages ${gqlMessages}
  finalCategories{
    objectives ${categoryFields}
    skills ${categoryFields}
    evaluation ${categoryFields}
  }
  evaluated ${user}
  validator ${user}
  evaluator ${user}
  dateChangedStatus {
    pending
    evaluated
    validated
    communicated
    rejected
  }
  ${objectives}
  ${skills}
}
`;
const histoticalPerformanceEvaluation = `
{
  _id
  year
  status
  strengths
  opportunities
  isAutoevaluation
  finalCategories{
    objectives ${categoryFields}
    skills ${categoryFields}
    evaluation ${categoryFields}
  }
  evaluated ${user}
  validator ${user}
  evaluator ${user}
  skills{
    _id
    name
    categorySelected ${categoryFields}
  }
}
`;

export const GET_EDD = gql`
    query getEDD(
        $rutSubordinate: String
        $admin: Boolean
    ) {
        getEDD(
          rutSubordinate: $rutSubordinate
          admin: $admin
        ) 
        {
          year
          evaluation ${edd}
          autoEvaluation ${edd}
        }
    }
`;

export const GET_PERFORMANCE_EVALUATION_HISTORICAL = gql`
    query getPerformanceEvaluationHistorial(
        $rutSubordinate: String
    ) {
        getPerformanceEvaluationHistorial(
          rutSubordinate: $rutSubordinate
        ) 
        {
          year
          evaluation ${histoticalPerformanceEvaluation}
          autoEvaluation ${histoticalPerformanceEvaluation}
        }
    }
`;

export const GET_EDD_HISTORICAL = gql`
      query getHistoricalEDD(
        $rutSubordinate: String
      ){
        getHistoricalEDD(
          rutSubordinate: $rutSubordinate
        ) 
        {
          year
          evaluation ${edd}
          autoEvaluation ${edd}
        }
    }
`;

export const GET_LAST_EDD_EVALUATION = gql`
    query getLastEDDEvaluation(
        $rut: String
    ) {
        getLastEDDEvaluation(
          rut: $rut
        ) 
        {
          strengths
          opportunities
          ${objectives}
          ${skills} 
          finalCategories {
            objectives ${categoryFields}
            skills ${categoryFields}
            evaluation ${categoryFields}
          }
        }
    }
`;
