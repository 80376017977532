import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Button } from 'antd';
import DetailsRow from '../../../../shared/cards/DetailsRow2';
import Separator from '../../../../shared/Separator';
import Card from '../../../../shared/cards/card';
import EvaluationSteps from '../../Evaluations/EvaluationSteps';
import {
  COMMUNICATED,
  VIEW_AS_EVALUATOR,
  VIEW_AS_VALIDATOR,
  getLabelStatus,
  getIconStatus,
  getEvaluatorAndValidator,
  VIEW_AS_EVALUATED
} from '../../../../../helpers/myEvolution/evaluations';
import EvaluationsMessages from '../../Evaluations/EvaluationsMessages';
import EvaluationSummary from '../EvaluationSummary';
import {
  showEvaluation,
  showAutoEvaluation
} from '../../../../../helpers/myTeam/myEvolution/evaluations/performance';
import InfoMessage from '../../../../shared/InfoMessage';

const PerformanceEvaluationCard = (props) => {
  const {
    viewAs,
    children,
    title,
    classes,
    evaluation,
    autoEvaluation,
    showSteps,
    handleModalOpinion,
    showButtonOpinion,
    isActive
  } = props;

  const {
    evaluator, validator, year, messages, status
  } = evaluation;

  const showAutoEval = showAutoEvaluation(autoEvaluation);

  const getMessageInfo = () => {
    if (!isActive) {
      if (status === COMMUNICATED) return null;
      if (status === 'PENDING') {
        if (viewAs === VIEW_AS_EVALUATED) {
          return 'No tienes evaluación de desempeño registrada en Mi Lugar para este periodo.';
        }
        return 'El colaborador no tiene evaluación de desempeño registrada en Mi Lugar para este periodo.';
      }
      return `La evaluación de desempeño ${year} no fue comunicada`;
    }
    return null;
  };
  return (
    <Card classes={classes}>
      {title && (
        <div>
          <div className='card-details-row mt-0'>
            <h3>{title}</h3>
          </div>
          <Separator />
        </div>
      )}
      <Fragment>
        <div className='card-details-row container-date-status '>
          <div>
            <i className='icon-ml-calendar-2' />
            <h6>{year}</h6>
          </div>
          <div>
            <i className={getIconStatus(status)} />
            <h6> {getLabelStatus(status)}</h6>
          </div>
        </div>
        <Separator />

        { getMessageInfo() ? (
          <InfoMessage classes='w-100 mb-3 mt-3' color='warning'>
            {getMessageInfo()}
          </InfoMessage>
        ) : (
          <Fragment>
            <DetailsRow
              contentData={getEvaluatorAndValidator(evaluator, validator)}
            />
            <Separator />
            {[VIEW_AS_EVALUATOR, VIEW_AS_VALIDATOR].includes(viewAs)
              && showSteps && (
              <EvaluationSteps
                classes='mb-2'
                isCompleted={status.toUpperCase() === COMMUNICATED}
                status={status}
              />
            )}

            <EvaluationsMessages messages={messages} viewAs={viewAs} />
            {showAutoEval && (
              <EvaluationSummary
                evaluation={autoEvaluation}
                classes={`mb-3 ${isEmpty(messages) ? 'mt-3' : ''}`}
              />
            )}
            {showEvaluation(viewAs, evaluation) && (
              <EvaluationSummary
                evaluation={evaluation}
                classes={`mb-3 ${
                  isEmpty(messages) && !showAutoEval ? 'mt-3' : ''
                }`}
              />
            )}

            {children}
          </Fragment>
        )}
        { viewAs === VIEW_AS_EVALUATED && showButtonOpinion

             && <div className='d-flex justify-content-center pt-1 pb-4'>
               <Button
                 type='primary'
                 className='evaluate-button'
                 onClick={() => handleModalOpinion()}
               >
             Opinar sobre mi evaluación
               </Button>
             </div>
        }

      </Fragment>
    </Card>
  );
};

PerformanceEvaluationCard.propTypes = {
  viewAs: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  contentData: PropTypes.array.isRequired,
  year: PropTypes.number.isRequired,
  title: PropTypes.string,
  classes: PropTypes.string,
  evaluation: PropTypes.object,
  autoEvaluation: PropTypes.object,
  showSteps: PropTypes.bool,
  handleModalOpinion: PropTypes.func,
  showButtonOpinion: PropTypes.bool,
  isActive: PropTypes.bool
};

PerformanceEvaluationCard.defaultProps = {
  viewAs: '',
  status: '',
  contentData: [],
  year: 0,
  title: 'Evaluación de desempeño',
  classes: '',
  evaluation: {},
  autoEvaluation: {},
  showSteps: true,
  handleModalOpinion: () => {},
  showButtonOpinion: false,
  isActive: true
};
export default PerformanceEvaluationCard;
