import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/client';

import FilterLisyBy from '../../../shared/filters/FilterListBy';
import StatusSelector from '../../compensaciones/merito/Look/StatusSlector';
import MLTable from '../../../shared/tables/MLTable';
import MLLoading from '../../../shared/MLLoading';
import MessageModal from '../../../shared/ModalMessage';
import { ADMIN_GET_USER_PERFORMANCE } from '../../../../graphql/mievolucion/admin/queries';
import { ADMIN_UPDATE_USER_PERFORMANCE, RESTORE_LAST_EDD_EVALUATION } from '../../../../graphql/mievolucion/admin/mutations';
import { checkRut } from '../../../../helpers/rut_validator';
import {
  GET_EDD,
  GET_LAST_EDD_EVALUATION
} from '../../../../graphql/mievolucion/performanceEvaluation/queries';
import { UPDATE_EDD_STATUS_BY_ADM } from '../../../../graphql/mievolucion/performanceEvaluation/mutations';
import { getFullName } from '../../../../helpers/user';
import ErrorMessage from '../../../shared/ErrorMessage';
import LastEDDEvaluation from './LastEDDEvaluation';

const context = { clientName: 'myEvolution' };

const Search = () => {
  const [isActive, activate] = useState(false);
  const [modal, setModal] = useState({ visible: false });
  const [requestState, fetchRequestState] = useState({ loading: false, data: null, error: null });
  const [requestStateEDD, fetchRequestStateEDD] = useState({
    loading: false, data: null, error: null
  });
  const [colorButton, setColorButton] = useState(false);
  const [buttonState, setButtonState] = useState(true);
  const [wrongRut, setWrongRut] = useState('');

  const [
    getEDD,
    {
      loading: loadingEDD,
      data: dataEDDServ,
      error: errorEDD
    }
  ] = useLazyQuery(GET_EDD, {
    context,
    fetchPolicy: 'network-only'
  });
  const [
    getLastEDDEvaluation,
    {
      loading: loadingLastEDDEvaluation,
      data: dataLastEDDEvaluation,
      error: errorLastEDDEvaluation
    }
  ] = useLazyQuery(GET_LAST_EDD_EVALUATION, {
    context,
    fetchPolicy: 'network-only'
  });

  const { getEDD: dataEDD = {} } = dataEDDServ || {};
  const { getLastEDDEvaluation: lastEDDEvaluation } = dataLastEDDEvaluation || {};

  const errorDisplayByAdm = (e) => {
    setModal({
      visible: true,
      type: 'error',
      title: e.message || 'Por favor, intente más tarde',
      buttons: [
        {
          text: 'Cerrar',
          type: 'primary',
          onClickButton: () => setModal({ ...modal, visible: false })
        }
      ]
    });
  };

  const [updateEDDStatusByAdm] = useMutation(UPDATE_EDD_STATUS_BY_ADM, {
    context,
    onCompleted: () => {
      setModal({
        visible: true,
        type: 'success',
        title: 'El estado de la EDD fue cambiada correctamente ',
        buttons: [
          {
            text: 'Cerrar',
            type: 'primary',
            onClickButton: () => setModal({ ...modal, visible: false })
          }
        ]
      });
      activate(false);
    },
    onError: errorDisplayByAdm
  });

  const errorDisplay = (e) => {
    setModal({
      visible: true,
      type: 'error',
      title: e.message || 'Por favor, intente más tarde',
      buttons: [
        {
          text: 'Cerrar',
          type: 'primary',
          onClickButton: () => setModal({ ...modal, visible: false })
        }
      ]
    });
  };

  const [restoreLastEvaluation, {
    loading: restoreLastEvaluationLoading
  }] = useMutation(RESTORE_LAST_EDD_EVALUATION, {
    context,
    onCompleted: () => {
      getEDD({ variables: { rutSubordinate: dataEDD?.evaluation?.rut, admin: true } });
      setModal({
        visible: true,
        type: 'success',
        title: 'Evaluación restaurada',
        buttons: [
          {
            text: 'Cerrar',
            type: 'primary',
            onClickButton: () => setModal({ ...modal, visible: false })
          }
        ]
      });
    },
    onError: errorDisplay
  });

  const [
    getUserPerformance,
    {
      data: { listPerformanceAdmin = {} } = {},
      loading: listLoading
    }
  ] = useLazyQuery(ADMIN_GET_USER_PERFORMANCE, {
    context,
    fetchPolicy: 'network-only',
    onError: errorDisplay
  });

  const handleRestoreEDD = () => {
    setModal({
      icon: 'icon-ml-warning',
      visible: true,
      title: '¿Estás seguro que deseas restaurar la evaluación con la información vista anteriormente?',
      message: 'Quedará un registro indicando que hiciste este cambio, la evaluación quedara Pendiente y el jefe evaluador debe volverla a enviar al validador, la autoevaluación del usuario también sera restaurada para que la realice de nuevo.',
      buttons: [
        {
          text: 'Cancelar',
          className: 'button-border-primary',
          onClickButton: () => setModal({ ...modal, visible: false })
        },
        {
          text: 'Sí, guardar cambios',
          type: 'primary',
          loadingButton: false,
          onClickButton: () => {
            setModal({ ...modal, visible: false });
            restoreLastEvaluation({
              variables: {
                eddID: dataEDD?.evaluation?._id
              }
            });
          }
        }
      ]
    });
  };

  const chooseMsgs = (action, status) => {
    switch (`${action}|${status}`) {
    case 'TO_UPDATE|PENDING_BY_COLABORATE':
      return {
        title: '¿Estás seguro que deseas dejar en pendiente la propuesta del Colaborador?',
        message: 'La Jefatura dejará de visualizar la propuesta y el Colaborador podrá editarla.'
      };
    case 'TO_UPDATE|PENDING_BY_BOSS':
      return {
        title: '¿Estás seguro que deseas dejar en pendiente por jefatura la propuesta del Colaborador?',
        message: 'La Jefatura podrá cascadear sus objetivos a sus reportes pendientes'
      };
    case 'TO_UPDATE|PENDING_EDIT_OBJ':
      return {
        title: '¿Estás seguro que deseas dejar en pendiente (Editar Objetivos) la propuesta del Colaborador?',
        message: 'La Jefatura dejará de visualizar la propuesta y el Colaborador podrá editarla.'
      };
    case 'TO_UPDATE|SENT':
      return {
        title: '¿Estás seguro que deseas enviar la propuesta a la Jefatura del Colaborador?',
        message: 'El Colaborador no podrá editar su propuesta luego de ser enviada a su Jefatura.'
      };
    case 'TO_UPDATE|CLOSED':
      return {
        title: '¿Estás seguro que deseas cerrar la propuesta del Colaborador?',
        message: 'La Jefatura del Colaborador no podrá editar la propuesta.'
      };
    case 'UPDATED|PENDING_EDIT_OBJ':
      return {
        title: 'La propuesta del Colaborador ha quedado pendiente (Editar Objetivos) de ser enviada'
      };
    case 'UPDATED|PENDING_BY_COLABORATE':
      return {
        title: 'La propuesta del Colaborador ha quedado pendiente de ser enviada'
      };
    case 'UPDATED|PENDING_BY_BOSS':
      return {
        title: 'Se logró cascadear los objetivos a sus reportes en estado pendiente'
      };
    case 'UPDATED|SENT':
      return {
        title: 'La propuesta del Colaborador se ha enviado a su Jefatura'
      };
    case 'UPDATED|CLOSED':
      return {
        title: 'La propuesta del Colaborador ha quedado cerrada'
      };
    case 'TO_UPDATE|RETURNED':
      return {
        title: 'La propuesta del Colaborador ha quedado devuelta'
      };
    case 'UPDATED|RETURNED':
      return {
        title: 'La propuesta del Colaborador ha quedado devuelta'
      };
    default:
      return '';
    }
  };

  const [updatePerformanceAdmin, {
    data: updatedData,
    loading: updateLoading
  }] = useMutation(ADMIN_UPDATE_USER_PERFORMANCE, {
    context,
    onCompleted: ({ updatePerformanceAdmin: result }) => {
      setModal({
        visible: true,
        type: 'success',
        ...chooseMsgs('UPDATED', result.status.current),
        buttons: [
          {
            text: 'Cerrar',
            type: 'primary',
            onClickButton: () => setModal({ ...modal, visible: false })
          }
        ]
      });
      activate(false);
    },
    onError: errorDisplay
  });

  const handleInputChange = (e) => {
    const { invalid, message } = checkRut(e.target.value);
    if (!invalid) {
      setColorButton(true);
      setButtonState(false);
      setWrongRut('');
    } else {
      setColorButton(false);
      setButtonState(true);
      const text = e.target.value !== '' ? message : '';
      setWrongRut(text);
    }
  };

  const handleSubmit = (status) => {
    fetchRequestState({ ...requestState, loading: true });
    setModal({ ...modal, visible: false });
    updatePerformanceAdmin({ variables: { rut: listPerformanceAdmin.user.rut, status } });
    fetchRequestState({ ...requestState, loading: false });
  };

  const handleSubmitEDD = (event, evaluation) => {
    fetchRequestStateEDD({ ...requestStateEDD, loading: true });
    setModal({ ...modal, visible: false });
    updateEDDStatusByAdm({ variables: { evaluationId: evaluation._id, statusInput: event.value } });
    fetchRequestStateEDD({ ...requestStateEDD, loading: false });
  };

  const handleButton = ({ value }) => {
    if (isActive) {
      setModal({
        icon: 'icon-ml-warning',
        visible: true,
        ...chooseMsgs('TO_UPDATE', value),
        buttons: [
          {
            text: 'Cancelar',
            className: 'button-border-primary',
            onClickButton: () => setModal({ ...modal, visible: false })
          },
          {
            text: 'Sí, guardar cambios',
            type: 'primary',
            loadingButton: false,
            onClickButton: () => handleSubmit(value)
          }
        ]
      });
    } else {
      activate(true);
    }
  };

  const handleButtonEDD = (event, evaluation) => {
    if (isActive) {
      setModal({
        icon: 'icon-ml-warning',
        visible: true,
        title: '¿Estás segur que deseas realizar el cambio?',
        buttons: [
          {
            text: 'Cancelar',
            className: 'button-border-primary',
            onClickButton: () => setModal({ ...modal, visible: false })
          },
          {
            text: 'Sí, guardar cambios EDD',
            type: 'primary',
            loadingButton: false,
            onClickButton: () => handleSubmitEDD(event, evaluation)
          }
        ]
      });
    } else {
      activate(true);
    }
  };

  const buildSelector = (defaultValue) => ({
    defaultValue,
    options: [
      {
        name: 'Pendiente por colaborador',
        value: 'PENDING_BY_COLABORATE'
      },
      {
        name: 'Pendiente por colaborador (Editar Objetivos)',
        value: 'PENDING_EDIT_OBJ'
      },
      {
        name: 'Pendiente por Jefatura',
        value: 'PENDING_BY_BOSS'
      },
      {
        name: 'Enviado a Jefatura',
        value: 'SENT'
      },
      {
        name: 'Cerrado (Cascadea objetivos)',
        value: 'CLOSED'
      }
    ]
  });

  const buildSelectorEDD = (defaultValue) => ({
    defaultValue,
    options: [
      {
        name: 'Pendiente',
        value: 'PENDING'
      },
      {
        name: 'Evaluada',
        value: 'EVALUATED'
      },
      {
        name: 'Validada',
        value: 'VALIDATED'
      },
      {
        name: 'Comunicada',
        value: 'COMMUNICATED'
      }
    ]
  });

  const getDiffPending = (_updatedData, data) => {
    if (_updatedData) {
      if (_updatedData.updatePerformanceAdmin.objectiveEdit.status) {
        return 'PENDING_EDIT_OBJ';
      }
      return _updatedData.updatePerformanceAdmin.status.current;
    }
    if (data.performance.objectiveEdit.status) {
      return 'PENDING_EDIT_OBJ';
    }
    return data.performance.status.current === 'WITHOUTINFO' ? 'Sin información' : data.performance.status.current;
  };

  const dataToTable = (data) => {
    const { user, linealBoss, permission } = data;
    return {
      columns: [
        {
          title: 'Colaborador',
          dataIndex: 'user',
          key: 'user',
          className: 'table-td'
        }, {
          title: 'Permiso',
          dataIndex: 'permission',
          key: 'permission',
          className: 'table-td'
        },
        {
          title: 'Jefe Lineal',
          dataIndex: 'linearBoss',
          key: 'linearBoss',
          className: 'table-td'
        },
        {
          width: 220,
          title: 'Estado',
          dataIndex: 'status',
          key: 'status',
          className: 'table-td'
        }
      ],
      srcData: [
        {
          user: getFullName(user),
          linearBoss: getFullName(linealBoss),
          permission,
          status: <StatusSelector {...{
            ...requestState,
            isActive,
            widthOptional: true,
            handleButton,
            data: buildSelector(getDiffPending(updatedData, data))
          }} />
        }
      ]
    };
  };

  const EDDdataToTable = (data) => {
    const { evaluation } = data;
    return {
      columns: [
        {
          title: 'Tipo Eval',
          dataIndex: 'typeEval',
          key: 'typeEval',
          className: 'table-td'
        }, {
          title: 'Objetivos cerrados SI/NO',
          dataIndex: 'hasObj',
          key: 'hasObj',
          className: 'table-td'
        },
        {
          title: 'Evaluador',
          dataIndex: 'evaluador',
          key: 'evaluador',
          className: 'table-td'
        },
        {
          title: 'Validador',
          dataIndex: 'validador',
          key: 'validador',
          className: 'table-td'
        },
        {
          width: 220,
          title: 'Estado EDD',
          dataIndex: 'status',
          key: 'status',
          className: 'table-td'
        }
      ],
      srcData: [
        {
          typeEval: evaluation?.evaluated?.evaluationType,
          hasObj: evaluation?.evaluated?.atLeastOnceClosedStatus ? 'Si' : 'No',
          evaluador: getFullName(evaluation?.evaluator),
          validador: getFullName(evaluation?.validator),
          status: <StatusSelector {...{
            ...requestStateEDD,
            isActive,
            widthOptional: true,
            handleButton: (event) => handleButtonEDD(event, evaluation),
            data: buildSelectorEDD(evaluation?.status)
          }} />
        }
      ]
    };
  };

  const enviarDatos = (value) => {
    getUserPerformance({ variables: { rut: value } });
    getEDD({ variables: { rutSubordinate: value, admin: true } });
    getLastEDDEvaluation({ variables: { rut: value } });
  };

  return (
    <FilterLisyBy {...{
      colorButton,
      buttonState,
      handleInputChange,
      enviarDatos
    }}>
      {wrongRut && <p className='text-danger'><i>{wrongRut}</i></p>}
      {
        listLoading || updateLoading
          ? <MLLoading />
          : !isEmpty(listPerformanceAdmin)
          && <MLTable columnAndData={dataToTable(listPerformanceAdmin)} />
      }
      {
        loadingEDD && <MLLoading />
      }{

        !isEmpty(dataEDD) && isEmpty(errorEDD) && !loadingEDD
           && <MLTable columnAndData={EDDdataToTable(dataEDD)} />
      }
      {
        !isEmpty(errorEDD) && !loadingEDD && <ErrorMessage
          classes='mt-3'
          message={(errorEDD.toString()?.split(':')[1]) || 'EDD con error desconocido'} />

      }
      <LastEDDEvaluation
        restoreLastEvaluationLoading={restoreLastEvaluationLoading}
        currentEDD={dataEDD?.evaluation}
        loading={loadingLastEDDEvaluation}
        error={errorLastEDDEvaluation}
        lastEDDEvaluation={lastEDDEvaluation}
        handleRestoreEDD={handleRestoreEDD}
      />
      <MessageModal {...{ ...modal }} />
    </FilterLisyBy>
  );
};

export default Search;
