import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { filter, isEmpty, map } from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import InfoMessage from '../../../shared/InfoMessage';
import MLError from '../../../shared/Error';
import '../../../../static/styles/shared/MLOpinion/styles.less';
import RadioInput from '../../../shared/MLOpinion/RadioInput';
import TextAreaInput from '../../../shared/MLOpinion/TextAreaInput';
import YesNoInput from '../../../shared/MLOpinion/YesNoInput';
import ButtonSave from '../../../shared/MLOpinion/ButtonSave';
import {
  GET_LIST_QUESTIONS
} from '../../../../graphql/questions/queries';
import Loading from '../../../../containers/loading';
import { MY_EVOLUTION_CONTEXT } from '../../../../helpers/apollo';
import { SAVE_EDD_OPINION } from '../../../../graphql/mievolucion/performanceEvaluation/mutations';
import { fullCapitalizeFormat } from '../../../../helpers/strings';
import { GET_EDD } from '../../../../graphql/mievolucion/performanceEvaluation/queries';

const MLOpinion = (props) => {
  const {
    closeOpinion,
    evaluatorFullName,
    evaluationID,
    setModalMessage,
    refetchQueries = []
  } = props;
  const {
    loading: loadingQuestions,
    data: dataQuestions,
    error: errorQuestions
  } = useQuery(GET_LIST_QUESTIONS,
    {
      context: MY_EVOLUTION_CONTEXT,
      variables: { type: 'opinion-edd' }
    });

  const { listQuestions = [] } = dataQuestions || {};

  const [sendOpinion,
    { loading: loadingSendOpinion }] = useMutation(SAVE_EDD_OPINION, {
    context: MY_EVOLUTION_CONTEXT,
    refetchQueries: [
      {
        context: MY_EVOLUTION_CONTEXT,
        query: GET_EDD
      },
      ...refetchQueries
    ],
    onCompleted: () => {
      closeOpinion();
      setModalMessage({
        visible: true,
        type: 'success',
        title: '¡Tu opinión se envió con éxito!'
      });
    },
    onError: () => {
      setModalMessage({
        visible: true,
        type: 'error',
        title: 'No se pudo enviar tu opinión',
        message: 'Estamos trabajando en solucionarlo, por favor intenta enviarlo de nuevo más tarde.'
      });
    }
  });

  const setInitialData = (questions) => questions?.map((question) => (
    {
      ...question,
      value: null
    }
  ));

  const [questionsAndAnswers, setAnswers] = useState([]);
  const [userHadConversationithBoss, setUserHadConversationithBoss] = useState();

  useEffect(() => {
    if (!loadingQuestions && !isEmpty(listQuestions)) {
      const questionsWithValue = setInitialData(listQuestions);
      const questionsFilteredByTag = filter(questionsWithValue,
        (question) => question?.metadata?.tag?.includes(userHadConversationithBoss));
      setAnswers(questionsFilteredByTag);
    }
  }, [listQuestions, loadingQuestions, userHadConversationithBoss]);

  const validAnswers = filter(questionsAndAnswers,
    (item) => item.value !== null && item.value.trim().length > 0);

  if (errorQuestions) return <MLError />;
  if (loadingQuestions) return <Loading />;

  const Questions = () => {
    if (userHadConversationithBoss === undefined) return null;

    return questionsAndAnswers.map((question, indexQuestion) => (
      <div key={indexQuestion} className='box-questions'>

        {question.inputType === 'radio'
      && <RadioInput {...{
        question,
        onClick: (indexEmoticon) => {
          const newAnswersAndQuestions = questionsAndAnswers.map(
            (item, indexSetValueEmoticon) => (
              {
                ...item,
                value: indexQuestion === indexSetValueEmoticon
                  ? (indexEmoticon + 1).toString()
                  : item.value

              }
            )
          );
          setAnswers(newAnswersAndQuestions);
        }
      }}/>

        }
        { question.inputType === 'textarea'
      && <TextAreaInput
        {...{
          inputClassName: 'ml-textarea',
          question,
          onChange: (e) => {
            const newAnswersAndQuestions = questionsAndAnswers.map(
              (item, indexSetValueEmoticon) => (
                {
                  ...item,
                  value: indexQuestion === indexSetValueEmoticon
                    ? e.target.value
                    : item.value

                }
              )
            );
            setAnswers(newAnswersAndQuestions);
          }
        }}/>
        }
        { question.inputType === 'yes-no'
        && (
          <YesNoInput
            {...{
              question,
              onChange: (e) => {
                const newAnswersAndQuestions = questionsAndAnswers.map(
                  (item, indexSetValueEmoticon) => (
                    {
                      ...item,
                      value: indexQuestion === indexSetValueEmoticon
                        ? e.target.value
                        : item.value
                    }
                  )
                );
                setAnswers(newAnswersAndQuestions);
              }
            }}/>
        )
        }
      </div>));
  };

  return (
    <div className='d-flex justify-content-center'>
      <div className='col-12 col-sm-12 col-md-8 col-lg-6 box-evaluation-opinion'>

        <InfoMessage
          color={'warning'}
          classes='w-100'
          message={`Danos tu opinión sobre la conversación acerca de la evaluación que tuviste con ${fullCapitalizeFormat(evaluatorFullName)}.`}/>

        <div className='box-questions'>
          <YesNoInput
            {...{
              yesValue: 'userHadConversationWithBoss',
              noValue: 'userHadNotConversationWithBoss',
              question: { name: '¿Te has reunido con tu jefe a hablar sobre los resultados de tu evaluación?' },
              onChange: (e) => {
                setUserHadConversationithBoss(e.target.value);
                setAnswers(setInitialData(listQuestions));
              }
            }}/>
        </div>

        {Questions()}

        <ButtonSave {
          ...{
            loading: loadingSendOpinion,
            disabledButton: isEmpty(validAnswers),
            saveOpinion: () => {
              sendOpinion({
                variables: {
                  _id: evaluationID,
                  userHadConversationWithBoss: userHadConversationithBoss === 'userHadConversationWithBoss',
                  opinion: map(validAnswers, (validAnswer) => ({
                    question: {
                      _id: validAnswer._id,
                      name: validAnswer.name
                    },
                    value: validAnswer.value
                  }))
                }
              });
            },
            closeOpinion,
            labelButton: 'Enviar mi opinión'
          }
        } />
      </div>
    </div>
  );
};

MLOpinion.propTypes = {
  evaluatorFullName: PropTypes.string,
  questions: PropTypes.array,
  closeOpinion: PropTypes.func,
  setModalMessage: PropTypes.func
};

MLOpinion.defaultProps = {
  evaluatorFullName: '',
  questions: [],
  closeOpinion: () => {},
  setModalMessage: () => {}
};
export default MLOpinion;
