import moment from 'moment';

const quarter = `q${moment().quarter()}`;

const quarterDictionary = {
  q1: 'Marzo',
  q2: 'Junio',
  q3: 'Octubre',
  q4: 'Diciembre'
};

const quarterDictionaryAux = {

  q1: 'Marzo',
  q2: 'Julio',
  q3: 'Noviembre',
  q4: '-'
};

const getPreviousQuarter = (q) => (q === 'q5' ? 'q4' : `q${Object.keys(quarterDictionary).indexOf(q)}`);

const initiativesDictionary = {
  WITHOUTINFO: 'Sin información',
  NOT_INIT: 'No Iniciada',
  IN_PROGRESS: 'En Proceso',
  FINISHED: 'Finalizada',
  CANCELLED: 'Cancelada'
};

const canEditAdvance = ({ userView, performanceData }) => {
  const { quarterCurrent, statusByQuarter, status } = performanceData;
  const allQs = Object.keys(quarterDictionary);
  const quartersLimitToCheck = parseInt(quarterCurrent.replace('q', ''), 0) - 1;
  const quartersToCheck = allQs.slice(0, quartersLimitToCheck);
  let can = false;
  let verifyStatus;
  if (userView) {
    verifyStatus = () => status === 'PENDING' || status === 'RETURNED';
  } else {
    verifyStatus = () => status === 'SENT';
  }
  quartersToCheck.forEach((q) => {
    if (statusByQuarter[q] === 'CLOSED' && verifyStatus()) can = true;
  });
  return can;
};

export {
  quarter,
  quarterDictionary,
  quarterDictionaryAux,
  initiativesDictionary,
  getPreviousQuarter,
  canEditAdvance
};
