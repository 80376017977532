import React from 'react';
import PropType from 'prop-types';
import getDescriptionObjectivesData from '../../../../../../helpers/myEvolution/myPerformance/getDescriptionObjectivesData';

const DescriptionObjectives = ({
  can, bossName, isMobile, bossParams, isPulseEnabled
}) => {
  const { title, description } = getDescriptionObjectivesData({
    can, bossName, isMobile, bossParams, isPulseEnabled
  });

  return (
    <div className='description-objectives'>
      <span className='text-bold'>
        {title}
      </span>

      {!isMobile && <>&nbsp;</>}

      <span>
        {description}
      </span>
    </div>
  );
};

DescriptionObjectives.propTypes = {
  can: PropType.object,
  bossName: PropType.string,
  isMobile: PropType.bool,
  bossParams: PropType.object
};

DescriptionObjectives.defaultProps = {
  can: {},
  bossName: '',
  isMobile: false,
  bossParams: {}
};

export default DescriptionObjectives;
