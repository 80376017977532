import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import BannerLayout from '../../../layouts/banner';
import MLTabs from '../../../components/shared/MLTabs/MLTabsByRoute';
import { completePath } from '../../../helpers/strings';
import { useWindowWidthSize } from '../../../helpers/browser';
import { smallDevice } from '../../../helpers/deviceVariables';
import getURLParams from '../../../helpers/routing/getURLParams';
import '../../../static/styles/mievolucion/myPerformance/main.less';
import {
  PERFORMANCE_YEAR, DESEMPENO_URL, EDD_URL, FEEDBACK_URL
} from '../../../helpers/myEvolution/myPerformance';

const MiDesempeño = (props) => {
  const { pathname } = useLocation();
  const query = getURLParams();
  const id = query.get('id');
  const [showTabs, setShowTabs] = useState(true);
  const width = useWindowWidthSize();
  const banner = {
    imageBanner: `${completePath('web/mi-evolucion/banner/midesempeno-banner.jpg')}`,
    imageAvatar: `${completePath('web/mi-evolucion/avatars/midesempeno-avatar.jpg')}`,
    title: 'Mi Desempeño'
  };

  const isMobile = width <= smallDevice;
  const {
    me: {
      permissions: {
        MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_DESEMPEÑO = false,
        MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_EDD = false,
        MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_DESEMPEÑO_PULSO_MANUAL = false,
        MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_FEEDBACK = false
      }
    },
    children
  } = props;

  useEffect(() => {
    if (isMobile && ((/mi-equipo+/.test(pathname) && id) || /historial+/.test(pathname))) {
      setShowTabs(false);
    } else {
      setShowTabs(true);
    }
  }, [pathname, width]);

  const primaryActiveTab = () => {
    if (MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_DESEMPEÑO
       && pathname.match(DESEMPENO_URL)) return DESEMPENO_URL;
    if (MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_EDD && pathname.match(EDD_URL)) return EDD_URL;
    if (MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_FEEDBACK && pathname.match(FEEDBACK_URL)) return `${FEEDBACK_URL}/hacia-mí`;
    return pathname;
  };
  const manualObjectives = MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_DESEMPEÑO_PULSO_MANUAL;

  const primaryTabs = [

    ...(MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_DESEMPEÑO ? [
      {
        title: `${manualObjectives ? 'Mis Pulsos' : 'Objetivos'} ${PERFORMANCE_YEAR}`,
        key: '/mi-evolucion/mi-desempeño/desempeño'
      }

    ] : []),

    ...(MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_EDD ? [
      {
        title: 'Evaluación de Desempeño',
        key: '/mi-evolucion/mi-desempeño/evaluación-de-desempeño'
      }

    ] : []),

    ...(MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_FEEDBACK ? [
      {
        title: 'Feedback',
        key: '/mi-evolucion/mi-desempeño/feedback/hacia-mí'
      }

    ] : [])
  ];

  const child = (
    <Fragment>
      { showTabs
        && <MLTabs
          options={primaryTabs}
          defaultActiveKey={primaryActiveTab()}
          activeTab={primaryActiveTab()}
          tabsOnly
        />
      }
      {
        <>
          {children}
        </>
      }
    </Fragment>
  );

  return (
    <div className='ml-mievolucion-mi-gestion-mi-desempeno'>
      {!isMobile ? (
        <BannerLayout {...{
          ...banner
        }}>
          {child}
        </BannerLayout>
      ) : child}
    </div>
  );
};

export default MiDesempeño;
